<template>
  <div class="login">
    <div class="header">
      <img class="bg" src="@/assets/data-screen/header-bg.png" alt />
      <span class="title">淮安清江浦区智慧水务</span>
    </div>
    <div class="card">
      <img class="bg" src="@/assets/login/login-bg.png" alt />
      <div class="title">欢迎登录</div>

      <div class="content">
        <div class="field">
          <img class="icon" src="@/assets/login/user.png" alt />
          <div class="divider"></div>
          <input type="text" placeholder="用户名" v-model="name" />
        </div>
        <div class="field">
          <img class="icon" src="@/assets/login/password.png" alt />
          <div class="divider"></div>
          <input type="password" placeholder="密码" v-model="password" />
        </div>

        <img
          class="button"
          src="@/assets/login/button.png"
          @click="login"
          alt
        />
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login";
import encrypt from "@/utils/encrypt";

export default {
  data() {
    return {
      name: "",
      password: "",
    };
  },

  methods: {
    login() {
      if (!this.name || !this.password) {
        this.$message.error("请填写用户名或密码");
        return;
      }

      const hide = this.$message.loading("登陆中...");

      const params = {
        name: this.name,
        password: encrypt.encrypt(this.password),
      };

      login(params)
        .then((res) => {
          if (res && res.token && res.user) {
            window.localStorage.setItem("token", res.token);
            window.localStorage.setItem("user", JSON.stringify(res.user));
            this.$router.push("/");
          }
        })
        .finally(() => {
          hide();
        });
    },
  },
};
</script>


<style lang="less" scoped>
.login {
  height: 100vh;
  padding-top: 0.5em;
  box-sizing: border-box;
  background-image: url(../../assets/login/page-bg.png);
  background-position: bottom;
  background-size: cover;
}

.header {
  position: relative;
  .bg {
    display: block;
    width: 100%;
  }
  .title {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);

    letter-spacing: 0.05em;
    font-size: 4.8vh;
    font-family: YouSheBiaoTiHei;
    background-image: linear-gradient(to bottom, #ffffff, #71ffec);
    -webkit-background-clip: text;
    color: transparent;
  }
}

.card {
  width: 45vw;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  .bg {
    width: 100%;
    display: block;
  }
  .title {
    position: absolute;
    top: -1em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 4vh;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 3px 6px #71ffec;
  }

  .content {
    position: absolute;
    top: 18%;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
  }
}

.field {
  display: flex;
  align-content: center;

  background: linear-gradient(
    90deg,
    rgba(113, 255, 236, 0) 0%,
    rgba(113, 255, 236, 0.3) 52%,
    rgba(113, 255, 236, 0) 100%
  );
  margin-bottom: 1.5em;
  padding: 0.7em;

  .icon {
    margin-left: 1.5em;
    margin-right: 1.5em;
    height: 1.5em;
  }
  .divider {
    height: 1.5em;
    margin-right: 1.5em;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    flex-shrink: 0;
  }
  input {
    background-color: transparent;
    border-width: 0;
    outline: none;
    color: #fff;
    font-size: 1.3em;

    padding: 0;
    line-height: 1em;
    margin-top: -0.1em;
  }
  input::placeholder {
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
  }
}

.button {
  width: 100%;
  cursor: pointer;
}
</style>