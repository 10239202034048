

import request from '../request'

export function login(data) {
    return request({
        url: '/basic-service/auth/login',
        method: 'post',
        data
    })
}

export function fetchUserPermissionList(params) {
    return request({
        url: `/basic-service/permission/byuser?id=${params.id}`
    })
}
